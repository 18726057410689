import { Outlet } from 'react-router-dom'
import MailTo from './components/MailTo'
import { PopupWidget } from "react-calendly";
import {
  Header,
  Body,
  Footer,
  Rights,
  Center
} from './styles'
import ToogleLanguage from './components/ToogleLanguage'
import ToogleCalendly from './components/ToogleCalendly'
import ToogleMenu from './components/ToogleMenu'
import SocialNetworks from './components/SocialNetworks'
import { useLayout } from '../../providers/LayoutProvider'
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import { CitrixIcon } from '../../components/Icons/CitrixIcon';

interface ComponentProps { }

const Component = ({ }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const { layout } = useLayout()
  const { t } = useTranslation()

  return (
    <>
      <Header className={layout.loaded ? 'show' : ''}>
        <ToogleCalendly />
        <ToogleLanguage />
      </Header>
      <Body>
        <Outlet />
      </Body>
      <Footer className={layout.loaded ? 'show' : ''}>
        <SocialNetworks />
        <Center>
          <div>
            <MailTo />
            <Rights>©2025 Innen. {isMobile && <br />} {t('rights')}.</Rights>
          </div>
          <CitrixIcon style={{ fontSize: 70 }} />
        </Center>
      </Footer>
    </>
  )

}

export default Component
